import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/potwin';

const PotwinPage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;

	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);
	const imageData = useStaticQuery(imageQuery);
	return (
		<Layout location={location}>
			<SEO title='Potwin Construction' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					Building a Digital Footprint
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							Potwin Construction was seeking to take their
							business to the next level. They wanted to be
							defined by professional looking branding and extend
							their web presence outside of their Facebook page to
							reaching new customers.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.services.childImageSharp.fluid}
						alt='potwin construction services'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Researching the Competition</HeadingArrow>
						<p>
							My first step for the project was to conduct market
							research of local and national construction
							companies to see how they portray their services and
							to identify common branding elements. The goal for
							Potwin Construction was to make it clear that they
							provided construction services with just enough
							defining characteristics to make them stand out from
							their competition.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.moodboard.childImageSharp.fluid}
						alt='potwin construction moodboard exploration'
						border
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Building a Brand</HeadingArrow>
						<p>
							For the brand logo, I knew I wanted to shy away from
							using house or roofing imagery to help differentiate
							from competitors. I created several sketch
							iterations of possible logo shapes before settling
							on a simple and clean nested hammer design. After
							selecting the shape, I created several more versions
							with small adjustments to find the best overall
							balance.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.shape.childImageSharp.fluid}
						alt='potwin construction symbol exploration'
						border
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Looking Closely at Type</HeadingArrow>
						<p>
							When deciding on a font, I like to write out the
							business name and look at each letter combination to
							locate any challenges. For Potwin Construction, I
							knew that I wanted an open "P", and slightly
							skinnier "O" and "W" shapes to improve balance. I
							settled on Yantramanav since it fit my goals and had
							some quirky typographic details like a curly leg on
							capital R.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.type.childImageSharp.fluid}
						alt='potwin construction type exploration'
						border
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Developing the Web Presence</HeadingArrow>
						<p>
							With the branding in place, I next created the
							landing page for Potwin Construction. I settled on
							an efficient one page design, since the company was
							just starting out and didn't have a lot of content
							to fill a multi-page website. I used a web framework
							called Gatsby, which has several performance
							benefits that make it load almost instantly and
							provides several optimized image features.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.process.childImageSharp.fluid}
						alt='potwin construction process'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Showing Off The Work</HeadingArrow>
						<p>
							To display the companies work photos, I developed
							several custom components including a lightbox modal
							and a carousel. I used GraphQL to pull in data
							during the website's build process and create
							optimized images that swap out in real time
							depending on which screen size the website is viewed
							at.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.modal.childImageSharp.fluid}
						alt='potwin construction modal'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Wrapping Up</HeadingArrow>
						<p>
							To help tie the project all together, I developed a
							new set of business cards for the owner to help
							spread the message for the website. Unfortunately
							the project was canceled before launch due to
							Covid-19, but I'm still proud of the work that was
							created!
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.card.childImageSharp.fluid}
						alt='potwin construction business card'
						border
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default PotwinPage;

const imageQuery = graphql`
	query PotwinQuery {
		mainImg: file(
			relativePath: { eq: "case-study/potwin/potwin-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		services: file(
			relativePath: { eq: "case-study/potwin/potwin-services.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		moodboard: file(
			relativePath: { eq: "case-study/potwin/potwin-moodboard.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		shape: file(
			relativePath: { eq: "case-study/potwin/potwin-symbol-refine.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		type: file(
			relativePath: { eq: "case-study/potwin/potwin-type-test.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		process: file(
			relativePath: { eq: "case-study/potwin/potwin-process.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		modal: file(
			relativePath: { eq: "case-study/potwin/potwin-modal.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		card: file(
			relativePath: { eq: "case-study/potwin/potwin-business-card.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
